import React, { createContext, useEffect, useContext, useMemo, useState, useCallback, useRef } from 'react';
import { useQuerySearchItems } from 'hooks/queries/useQuerySearchItems/useQuerySearchItems';
import { PaginationState, Updater } from '@tanstack/react-table';
import { ItemParent, SearchItemsQuery } from '__generated__/graphql';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';

type InventoryDetail = Exclude<
  Exclude<SearchItemsQuery['searchItems']['items'][0]['inventoryDetail'], null>,
  undefined
>;

type Item = {
  itemNumber: string;
  inventory?: Inventory[];
  totalOnHand: number;
  quantity: number;
  unitCost: number;
  adjCost: number;
  margin: number;
  lineTotal?: number;
  description: string;
  leadTime: string;
  price?: number;
  tax?: string;
  grossProfit?: number;
  grossProfitPercent?: number;
  vendorQuoteReference?: string;
  storeDisplayName?: string | null;
  parent?: ItemParent | null;
  reelQuantityAvailable?: number | null;
  isInactive?: boolean | null;
  inventoryDetail?: InventoryDetail | null;
};

export type Inventory = {
  locationId?: string;
  location?: string;
  binNumber?: string;
  quantity?: number;
  otherQuoteQuantity?: number;
  committed?: number;
  picked?: number;
  onHand?: number;
  totalOnHand?: number;
  quantitySelected?: number;
  locationQuantityAvailable?: number;
};

type QuoteItemsContextState = {
  quoteItemsPaginated?: QuoteItemsPaginated;
  searchLoading?: boolean;
  pagination: PaginationState;
  setPagination: (updater: Updater<PaginationState>) => void;
  fetchMoreItems: () => void;
  setQuoteItemsPaginated: (updater: Updater<QuoteItemsPaginated>) => void;
  defaultQuoteItemsPaginated: QuoteItemsPaginated;
  filters: ItemSearchFilters;
  setFilters: (updater: Updater<ItemSearchFilters>) => void;
  appliedFilters: ItemSearchFilters;
  setAppliedFilters: (updater: Updater<ItemSearchFilters>) => void;
  setPauseSearch: (updater: Updater<boolean>) => void;
  defaultItemFilters: ItemSearchFilters;
};

const QuoteItemsContext = createContext<QuoteItemsContextState | undefined>(undefined);

type QuoteItemsProviderProps = {
  children: React.ReactNode;
};

export type ItemSearchFilters = {
  searchQuery: string;
  inStock: boolean;
  itemSku: string;
  itemSubSku: string;
  ignoreClientWarehouses: boolean;
  hideInactive: boolean;
  hideChildItems: boolean;
  vendor: string;
  type: string;
  fiberVendor: string;
};

type QuoteItemsPaginated = {
  quoteItems: Item[];
  total: number;
  pages: number;
};

const defaultQuoteItemsPaginated = {
  quoteItems: [],
  total: 0,
  pages: 0,
};

export const defaultItemFilters: ItemSearchFilters = {
  searchQuery: '',
  inStock: false,
  itemSku: '',
  itemSubSku: '',
  ignoreClientWarehouses: false,
  hideInactive: true,
  hideChildItems: false,
  vendor: '',
  type: '',
  fiberVendor: '',
};

const getFormattedQuoteItems = (query: SearchItemsQuery): Item[] => {
  const items = query.searchItems.items;

  return items.map((item) => {
    const inventory: Inventory[] = [];

    item.inventoryDetail?.binNumber?.forEach((location) => {
      if (!Number(location.onHandAvail)) {
        return;
      }

      const inv: Inventory = {
        binNumber: location.binNumber?.name || undefined,
        location: location.info?.name || undefined,
        locationId: location.info?.id || undefined,
        totalOnHand: Number(location.onHandAvail || 0),
        committed: Number(location.committedQtyPerLocation || 0),
        picked: Number(location.quantityPicked || 0),
        onHand: Number(location.onHand || 0),
        locationQuantityAvailable: Number(location.locationQuantityAvailable ?? '0'),
      };
      inventory.push(inv);
    });

    // Only pulling two fields from the DB, so this will fill in the rest of the fields for now
    const totalOnHand = Number(
      item.inventoryDetail?.binNumber?.reduce(
        (total: number, bin: { onHandAvail?: string | number | null }) => total + Number(bin.onHandAvail ?? 0),
        0
      ) ?? 0
    );

    const cost = item.unitCost ?? 0;

    return {
      ...item,
      inventory,
      itemNumber: item.itemId,
      description: item.description ?? '',
      totalOnHand,
      quantity: 0,
      unitCost: cost,
      adjCost: cost * 1.2, //apply a default 20% markup
      margin: 20,
      price: 0,
      tax: 'Yes',
      lineTotal: 0,
      grossProfit: 0,
      grossProfitPercent: 0,
      vendorQuoteReference: '81',
      leadTime: item.leadTime ?? '',
    };
  });
};

const QuoteItemsContextProvider: React.FC<QuoteItemsProviderProps> = ({ children }) => {
  const { clientData } = useQuoteContext();
  const [quoteItemsPaginated, setQuoteItemsPaginated] = useState<QuoteItemsPaginated>(defaultQuoteItemsPaginated);
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: 20, pageIndex: 0 });
  const [filters, setFilters] = useState<ItemSearchFilters>(defaultItemFilters);
  const [appliedFilters, setAppliedFilters] = useState<ItemSearchFilters>(defaultItemFilters);
  const [pauseSearch, setPauseSearch] = useState(true);
  const pauseSearchRef = useRef(pauseSearch);
  pauseSearchRef.current = pauseSearch;
  const aborterRef = useRef(new AbortController());
  const { data, loading, fetchMore } = useQuerySearchItems(
    { input: { client: clientData?.id, ...appliedFilters } },
    {
      skip: !clientData?.id || pauseSearch,
      context: {
        fetchOptions: {
          signal: aborterRef.current.signal,
        },
      },
    }
  );

  // We need to abort the previous search query when the filters change
  //  so the old query results do not overwrite the new results if the old query was slower
  const setAppliedFiltersAndAbort = useCallback((updater: Updater<ItemSearchFilters>) => {
    aborterRef.current.abort();
    setTimeout(() => {
      setAppliedFilters(updater);
      aborterRef.current = new AbortController();
    }, 50);
  }, []);

  const fetchMoreItems = useCallback(async () => {
    await fetchMore({
      variables: {
        input: {
          client: clientData?.id,
          ...appliedFilters,
          page: pagination.pageIndex + 1,
          perPage: pagination.pageSize,
        },
      },
    })
      .then((result) => {
        if (quoteItemsPaginated) {
          const quoteItems = quoteItemsPaginated.quoteItems.concat(getFormattedQuoteItems(result.data));
          setQuoteItemsPaginated({
            quoteItems,
            total: result.data?.searchItems.total,
            pages: result.data?.searchItems.pages,
          });
        }
      })
      .catch((err) => {
        console.warn(err.message);
      });
  }, [pagination.pageIndex, pagination.pageSize, fetchMore, appliedFilters, quoteItemsPaginated, clientData]);

  useEffect(() => {
    if (data) {
      const quoteItems = getFormattedQuoteItems(data);

      setQuoteItemsPaginated({
        quoteItems,
        total: data?.searchItems.total,
        pages: data?.searchItems.pages,
      });
    }
  }, [data, appliedFilters]);

  const contextValue: QuoteItemsContextState = useMemo(
    () => ({
      quoteItemsPaginated,
      searchLoading: loading,
      pagination,
      setPagination,
      fetchMoreItems,
      setQuoteItemsPaginated,
      defaultQuoteItemsPaginated,
      filters,
      setFilters,
      appliedFilters,
      setAppliedFilters: setAppliedFiltersAndAbort,
      setPauseSearch,
      defaultItemFilters,
    }),
    [quoteItemsPaginated, loading, pagination, fetchMoreItems, filters, appliedFilters, setAppliedFiltersAndAbort]
  );

  return <QuoteItemsContext.Provider value={contextValue}>{children}</QuoteItemsContext.Provider>;
};

const useQuoteItemsContext = () => {
  const context = useContext(QuoteItemsContext);

  if (context === undefined) {
    throw new Error('useQuoteItemsContext was used outside of its Provider');
  }

  return context;
};

export { QuoteItemsContext, QuoteItemsContextProvider, useQuoteItemsContext };
