import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Updater } from '@tanstack/react-table';
import { Box, Button, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQueryGetItemSkus } from 'hooks/queries/useQueryGetItemSkus/useQueryGetItemSkus';
import { useQueryGetItemSubSkus } from 'hooks/queries/useQueryGetItemSubSkus/useQueryGetItemSubSkus';
import { useQuerySearchItems } from 'hooks/queries/useQuerySearchItems/useQuerySearchItems';
import { ItemSearchFilters, defaultItemFilters } from 'contexts/QuoteItemsContext/QuoteItemsContext';
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer/InfiniteScrollContainer';
import { LoadingContainer } from 'components/LoadingContainer/LoadingContainer';
import SearchResultHeader from 'components/SearchResultHeader/SearchResultHeader';
import { getBinsWithoutDuplicateLocations, stripHTML } from 'utils/functions';
import { StyledHeader } from 'components/StyledHeader/StyledHeader';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import SearchResult from 'components/SearchResult/SearchResult';
import FilterButton from 'components/FilterButton/FilterButton';
import Search from 'components/Search/Search';
import Flex from 'components/Flex/Flex';
import { Item } from 'utils/types';
import { ITEMS_SELECTION_STORAGE_KEY, ITEM_SEARCH_FILTER_KEY } from 'utils/constants';
import PillWithClose from 'components/PillWithClose/PillWithClose';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { BoldEllipsis } from 'components/BoldEllipsis/BoldEllipsis';
import { Ellipsis } from 'components/Ellipsis/Ellipsis';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from 'components/PageHeader/PageHeader';
import BlueButton from 'components/Button/Button';
import { css, styled } from '@mui/material/styles';

type PaginationState = {
  page: number;
  perPage: number;
};

export const ItemLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.hoverBlue};
    white-space: nowrap;
    text-decoration: none;
  `
);

const StickyContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.lightBlack};
    margin-top: -1rem;
    max-height: 100%;
    padding: 1rem 0;
    position: sticky;
    top: 4rem;
    width: 100%;
    z-index: 110;
  `
);

export function ItemSearch() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const [isFilterDrawOpen, setIsFilterDrawerOpen] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({ page: 0, perPage: 20 });
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);
  const { employeeId } = useAuthContext();
  const subSkusAborterRef = useRef(new AbortController());
  const itemsAborterRef = useRef(new AbortController());
  const [itemsList, setItemsList] = useState<Item[]>(() => {
    const storedList = window.localStorage.getItem(ITEMS_SELECTION_STORAGE_KEY);
    if (storedList) {
      return JSON.parse(storedList) as Item[];
    }
    return [];
  });

  const getStoredFilters = () => {
    try {
      const storedFilters = window.localStorage.getItem(ITEM_SEARCH_FILTER_KEY);
      return storedFilters ? (JSON.parse(storedFilters) as ItemSearchFilters) : defaultItemFilters;
    } catch (error) {
      console.warn('Failed to parse stored filters:', error);
      return defaultItemFilters;
    }
  };

  const [filters, setFilters] = useState<ItemSearchFilters>(getStoredFilters);
  const [appliedFilters, setAppliedFilters] = useState<ItemSearchFilters>(getStoredFilters);

  useEffect(() => {
    try {
      window.localStorage.setItem(ITEM_SEARCH_FILTER_KEY, JSON.stringify(filters));
    } catch (error) {
      console.warn('Failed to save filters to localStorage:', error);
    }
  }, [filters]);

  const { data: itemSkusData } = useQueryGetItemSkus({
    skip: !employeeId,
  });
  const { data: itemSubSkusData } = useQueryGetItemSubSkus(filters.itemSku, {
    skip: !employeeId,
    context: {
      fetchOptions: {
        signal: subSkusAborterRef.current.signal,
      },
    },
  });

  const filtersApplied = useMemo(() => {
    for (const _key in appliedFilters) {
      const key = _key as keyof typeof appliedFilters;
      if (appliedFilters[key] !== defaultItemFilters[key]) {
        return true;
      }
    }
    return false;
  }, [appliedFilters]);

  const {
    data: itemsData,
    loading,
    fetchMore,
  } = useQuerySearchItems(
    { input: appliedFilters },
    {
      context: {
        fetchOptions: {
          signal: itemsAborterRef.current.signal,
        },
      },
      skip: !appliedFilters.searchQuery && !filtersApplied,
    }
  );

  // We need to abort the previous search query when the filters change
  //  so the old query results do not overwrite the new results if the old query was slower
  const setAppliedFiltersAndAbort = useCallback((updater: Updater<ItemSearchFilters>) => {
    itemsAborterRef.current.abort();
    setTimeout(() => {
      setAppliedFilters(updater);
      itemsAborterRef.current = new AbortController();
    }, 50);
  }, []);

  const restorePreviouslySelectedFilters = () => {
    subSkusAborterRef.current.abort();
    setTimeout(() => {
      setFilters({ ...appliedFilters });
      subSkusAborterRef.current = new AbortController();
    }, 50);
  };

  const fetchNextPage = useCallback(() => {
    const nextPaginationOptions = {
      page: pagination.page + 1,
      perPage: pagination.perPage,
    };

    setPagination(nextPaginationOptions);

    fetchMore({
      variables: {
        input: {
          ...appliedFilters,
          ...nextPaginationOptions,
        },
      },
      // Updates the query cache with additional items
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          searchItems: {
            __typename: 'ItemSearchOutput',
            pages: fetchMoreResult.searchItems.pages,
            items: [...prev.searchItems.items, ...fetchMoreResult.searchItems.items],
            total: fetchMoreResult.searchItems.total,
          },
        };
      },
    }).catch((err) => {
      console.warn(err.message);
    });
  }, [pagination, fetchMore, appliedFilters]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPagination((prev) => ({ ...prev, page: 0 }));
    setFilters((prev) => ({
      ...prev,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    }));
  };

  const handleSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchQuery: event.target.value,
    }));
  };

  const handleSearchQueryChange = useCallback(
    (value: string) => {
      setPagination((prev) => ({ ...prev, page: 0 }));
      setAppliedFiltersAndAbort((prev) => ({
        ...prev,
        searchQuery: value,
      }));
    },
    [setAppliedFiltersAndAbort]
  );

  const handleAppliedFiltersChange = () => {
    setPagination({ page: 0, perPage: 20 });
    setAppliedFiltersAndAbort(filters);
  };

  const clearFilters = useCallback(() => {
    setFilters({ ...defaultItemFilters, inStock: false });
    setAppliedFilters({ ...defaultItemFilters, inStock: false });
    window.localStorage.removeItem(ITEM_SEARCH_FILTER_KEY);
  }, []);

  const handleAddItemToList = useCallback((item: Item) => {
    setSelectedItem(item);
    setItemsList((current) => {
      const newList = [...current];
      if (!current.find((existing) => existing.itemId === item.itemId)) {
        newList.push(item);
      }
      window.localStorage.setItem(ITEMS_SELECTION_STORAGE_KEY, JSON.stringify(newList));
      return newList;
    });
  }, []);

  const handleRemoveItemFromList = useCallback((item: Item, event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setItemsList((current) => {
      const index = current.map((cur) => cur.itemId).indexOf(item.itemId);
      if (-1 !== index) {
        const newList = [...current];
        //splice operates inline
        newList.splice(index, 1);
        window.localStorage.setItem(ITEMS_SELECTION_STORAGE_KEY, JSON.stringify(newList));
        return newList;
      }
      return current;
    });
  }, []);

  const handleAddToQuoteClick = useCallback(() => navigate('/create-quote'), [navigate]);

  return (
    <>
      <Flex w100 styles={{ justifyContent: 'space-between' }}>
        <PageHeader variant='h1' header='Items' />
        <BlueButton
          color='primary'
          variant={'contained'}
          disabled={itemsList.length === 0}
          onClick={handleAddToQuoteClick}
        >
          Add Items to Quote
        </BlueButton>
      </Flex>
      <StickyContainer>
        <Flex w100 gap={1} styles={{ justifyContent: 'space-between' }}>
          <Search
            placeholder='Search for an item by Millennium part number, Vendor part number, or item description…'
            name='searchQuery'
            value={filters.searchQuery ?? ''}
            onChange={handleSearchInputChange}
            onEnter={handleSearchQueryChange}
          />
          <FilterButton onClick={() => setIsFilterDrawerOpen((prev) => !prev)} isFilterApplied={filtersApplied} />
          <FilterMenu
            hideInactive={filters.hideInactive}
            handleHideInactiveChange={handleFilterChange}
            openDrawer={isFilterDrawOpen}
            setOpenDrawer={setIsFilterDrawerOpen}
            clearFilters={clearFilters}
            inStock={filters.inStock}
            handleInStockFilterChange={handleFilterChange}
            itemSku={filters.itemSku}
            handleItemSkuFilterChange={handleFilterChange}
            itemSubSku={filters.itemSubSku}
            handleItemSubSkuFilterChange={handleFilterChange}
            itemSkus={itemSkusData?.getItemSkus?.itemSkus}
            itemSubSkus={itemSubSkusData?.getItemSubSkus.itemSubSkus}
            ignoreClientWarehouses={filters.ignoreClientWarehouses}
            handleIgnoreClientWarehousesChange={handleFilterChange}
            handleVendorChange={handleFilterChange}
            vendor={filters.vendor}
            handleTypeChange={handleFilterChange}
            type={filters.type}
            hideChildItems={filters.hideChildItems}
            handleHideChildItemsChange={handleFilterChange}
            handleAppliedFiltersChange={handleAppliedFiltersChange}
            handleFiberVendorChange={handleFilterChange}
            fiberVendor={filters.fiberVendor}
            restorePreviouslySelectedFilters={restorePreviouslySelectedFilters}
          />
        </Flex>
      </StickyContainer>

      {itemsList && (
        <Flex wrap gap={1} styles={{ marginBottom: '1rem' }}>
          {itemsList.map((item) => (
            <PillWithClose key={item.id} handleClose={() => handleRemoveItemFromList(item)}>
              {item.isInactive && (
                <Tooltip title={'Inactive'}>
                  <ErrorOutlineIcon />
                </Tooltip>
              )}
              <BoldEllipsis>{item.storeDisplayName || item.itemId}</BoldEllipsis>
              <Ellipsis>{item.description}</Ellipsis>
            </PillWithClose>
          ))}
        </Flex>
      )}
      <Flex center column>
        <LoadingSpinner loading={loading}>
          {!!itemsData?.searchItems?.items.length && (
            <>
              <SearchResultHeader>
                <StyledHeader>Item #</StyledHeader>
                <StyledHeader>Warehouse</StyledHeader>
                <StyledHeader>Available</StyledHeader>
                <StyledHeader>Unit Cost</StyledHeader>
                <StyledHeader>Description</StyledHeader>
              </SearchResultHeader>
              <InfiniteScrollContainer id='scrollable'>
                <InfiniteScroll
                  dataLength={itemsData?.searchItems?.items.length ?? 0}
                  next={fetchNextPage}
                  hasMore={itemsData?.searchItems?.items.length < (itemsData?.searchItems?.total ?? 0)}
                  loader={
                    <LoadingContainer>
                      <LoadingSpinner loading={true} />
                    </LoadingContainer>
                  }
                  scrollableTarget='scrollable'
                >
                  {itemsData?.searchItems?.items.map((result, i) => (
                    <SearchResult
                      key={result.itemId + i}
                      onClick={() => handleAddItemToList(result)}
                      $selected={result.itemId === selectedItem?.itemId}
                    >
                      <div>
                        <ItemLink to={`/item/${result.id}`}>{result.itemId}</ItemLink>
                      </div>
                      <div>
                        {result.inventoryDetail &&
                          getBinsWithoutDuplicateLocations(result.inventoryDetail).map((bin) => (
                            <div key={bin.info?.id}>{bin.info?.name ?? ''}</div>
                          ))}
                      </div>
                      <div>
                        {result.inventoryDetail
                          ? getBinsWithoutDuplicateLocations(result.inventoryDetail).map((bin) => (
                              <div key={bin.info?.id}>{bin.locationQuantityAvailable || 0}</div>
                            ))
                          : 0}
                      </div>
                      <div>
                        {result.inventoryDetail &&
                          getBinsWithoutDuplicateLocations(result.inventoryDetail).map((bin) => (
                            <div key={`${bin.info?.id}-${result.itemId}`} style={{ width: '27%', textAlign: 'right' }}>
                              {Number(bin.averageCost)?.toFixed(2)}
                            </div>
                          ))}
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: stripHTML(result.description ?? '') }} />
                    </SearchResult>
                  ))}
                </InfiniteScroll>
              </InfiniteScrollContainer>
            </>
          )}
        </LoadingSpinner>
      </Flex>
    </>
  );
}
