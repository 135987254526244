import { css, styled } from 'styled-components';
import { ItemSearchInput } from '__generated__/graphql';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import Flex from 'components/Flex/Flex';
import Search from 'components/Search/Search';
import { useEffect, useMemo, useState } from 'react';
import { FormikCheckbox, FormikSelect, FormikValuesFrom, InputVariant } from 'utils/formik';
import { Container } from '@mui/material';
import { useItemFilterDropdowns } from '../hooks/useItemFilterDropdowns';
import { InputLabel } from 'components/InputLabel/InputLabel';

type ItemsFilterProps = {
  filters: ItemSearchInput;
  setFilters: React.Dispatch<React.SetStateAction<ItemSearchInput>>;
};

const DrawerContainer = styled(Container)(
  ({ theme }) => css`
    > :not(:last-child) {
      padding-bottom: 1rem;
      border-bottom: 1px solid ${theme.colors.lightBlack} !important;
    }
  `
);

const ItemsFilter: React.FC<ItemsFilterProps> = ({ filters, setFilters }: ItemsFilterProps) => {
  const [selectedSku, setSelectedSku] = useState<string>('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { skuOptions, subSkuOptions, vendorOptions, typeOptions } = useItemFilterDropdowns({
    sku: selectedSku,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilters((prev) => ({ ...prev, searchQuery: searchText }));
    }, 500);
    return () => clearTimeout(timeout);
  }, [setFilters, searchText]);

  const formFields = useMemo(
    () => ({
      inStock: 'In Stock',
      ignoreClientWarehouses: 'Ignore Client Warehouses',
      hideInactive: 'Hide Inactive',
      hideChildItems: 'Hide Child Items',
      itemSku: 'SKU',
      itemSubSku: 'Sub SKU',
      vendor: 'Vendor',
      fiberVendor: 'Fiber Vendor',
      type: 'Type',
    }),
    []
  );
  type FormikValues = FormikValuesFrom<typeof formFields, ItemSearchInput>;

  const initialValues = () => ({ ...filters });

  const toGetSalesOrderInput = (values: FormikValues) => {
    return { ...filters, ...values };
  };

  const onSubmit = (values: FormikValues) => {
    setFilters(toGetSalesOrderInput(values));
    setDrawerOpen(false);
  };

  return (
    <Flex w100 styles={{ alignItems: 'center', justifyContent: 'space-between' }} gap={1}>
      <Search
        placeholder='Search for an item by Millennium part number, Vendor part number, or item description...'
        onEnter={(value) => setSearchText(value)}
      />
      <FilterDrawer<FormikValues>
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        requiredFields={[]}
        formFields={formFields}
        initialValues={initialValues()}
        onSubmit={onSubmit}
      >
        {({ values, handleChange }) => {
          const props = { handleChange, values, requiredFields: [], formFields, variant: InputVariant.Drawer };
          return (
            <DrawerContainer>
              <Flex styles={{ paddingTop: '2rem' }} column gap={0}>
                <InputLabel>Availability</InputLabel>
                <FormikCheckbox {...props} name='inStock' />
                <FormikCheckbox {...props} name='ignoreClientWarehouses' />
                <FormikCheckbox {...props} name='hideInactive' />
                <FormikCheckbox {...props} name='hideChildItems' />
              </Flex>
              <FormikSelect
                {...props}
                name='itemSku'
                options={skuOptions}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedSku(event.target.value);
                  handleChange(event);
                }}
              />
              <FormikSelect {...props} name='itemSubSku' options={subSkuOptions} />
              <FormikSelect {...props} name='vendor' options={vendorOptions} />
              <FormikSelect {...props} name='fiberVendor' options={vendorOptions} />
              <FormikSelect {...props} name='type' options={typeOptions} />
            </DrawerContainer>
          );
        }}
      </FilterDrawer>
    </Flex>
  );
};

export default ItemsFilter;
